var MultiFileUploader = function (element, options) {

    this.options = options
    this.$container = $(element)
    this.$el = $('.content', element)
    this.$clickable = $('.clickable', element)
    this.$template = $('.template', element)
    this.$fileHolder = $('div.img', this.$el)

    var acceptedFiles = this.options.fileTypes
    if (acceptedFiles == '*') acceptedFiles = null
    var maxFiles = (this.options.maxFiles == '1') ? 1 : null

    this.dropzone = new Dropzone(this.$container.get(0), {
        url: window.location,
        uploadHanlder: 'renderForm::onUploadFile',
        clickable: this.$clickable.get(0),
        previewsContainer: this.$el.get(0),
        previewTemplate: this.$template.html(),
        acceptedFiles: acceptedFiles,
        maxFilesize: this.options.maxSize,
        paramName: 'file_data',
        maxFiles: maxFiles,
        dictFileTooBig: this.options.dictFileTooBig,
        dictDefaultMessage: this.options.dictDefaultMessage,
        dictFallbackMessage: this.options.dictFallbackMessage,
        dictFallbackText: this.options.dictFallbackText,
        dictInvalidFileType: this.options.dictInvalidFileType,
        dictResponseError: this.options.dictResponseError,
        dictCancelUpload: this.options.dictCancelUpload,
        dictCancelUploadConfirmation: this.options.dictCancelUploadConfirmation,
        dictRemoveFile: this.options.dictRemoveFile,
        dictRemoveFileConfirmation: null,
        dictMaxFilesExceeded: this.options.dictMaxFilesExceeded,
    })

    var self = this

    this.dropzone.on('error', function(file, error){
        alert(error)
    })

    this.dropzone.on('sending', function(file, xhr, formData) {
        formData.append('fieldId', self.options.fieldId)
        self.addExtraFormData(formData)
    })

    this.dropzone.on('success', function(file, response){
        self.updateFile(file, response)
    })

    this.dropzone.on('removedfile', function(file){

        console.log('MultiFileUploader.removedfile')
        self.removeFile(file)
    })
}

MultiFileUploader.prototype.addExtraFormData = function(formData) {
    if (this.options.extraData) {
        $.each(this.options.extraData, function (name, value) {
            formData.append(name, value)
        })
    }

    var $form = this.$el.closest('form')
    if ($form.length > 0) {
        $.each($form.serializeArray(), function (index, field) {
            formData.append(field.name, field.value)
        })
    }
}

MultiFileUploader.prototype.updateFile = function(file, response) {

    var $preview = $(file.previewElement)

    if (response.id) {
        $preview.data('fileId', response.id)

        // No thumbs is returned.
        //$img = $('.thumbnail', $preview)
        //$img.attr('src', response.thumb)
    }
}

MultiFileUploader.prototype.removeFile = function($file) {

    var $preview = $($file.previewElement)
    var fileId = $preview.data('fileId')
    if (fileId) {
        var request = this.$container.closest('form').request(this.options.handler, {
            data: {
                fileId: fileId,
                mode: 'delete'
            },
            redirect: false
        })
    }
}

MultiFileUploader.DEFAULTS = {
    handler: 'onUpdateFile',
    maxSize: null,
    fileTypes: null
}

var old = $.fn.multiFileUploader

$.fn.multiFileUploader = function (option) {
    return this.each(function () {
        var $this = $(this)
        var data  = $this.data('oc.multiFileUploader')
        var options = $.extend({}, MultiFileUploader.DEFAULTS, $this.data(), typeof option == 'object' && option)

        if (!data) $this.data('oc.multiFileUploader', (data = new MultiFileUploader(this, options)))
        if (typeof option == 'string') data[option].call($this)
    })
}

$.fn.multiFileUploader.Constructor = MultiFileUploader

$.fn.multiFileUploader.noConflict = function () {
    $.fn.multiFileUploader = old
    return this
}

export function initFileUpload(classeContainerFormBuilder) {
  $(classeContainerFormBuilder + ' [data-control=multi-file-uploader]').multiFileUploader()
}

// ---------------------------
// Google Recaptch
// ---------------------------
export function recaptchaFormsCallback() {

    if ( $('.form-builder-recaptcha').length ) {

        // Disabling submit Button.
        $('.form-builder-recaptcha').each(function() {
            let formId = $(this).data('form');
            $('.form-builder-submit').addClass('disabled').prop('disabled', true);
        });

        // À cause de barba, on peut pas utiliser le callBack de la librairie.
        // Il se peut donc qu'on load de la page, la librairie soit pas complètement chargé
        // avant l'éxécution de grecaptcha.render.
        // On doit attendre son chargement complet.
        if (grecaptcha && grecaptcha.render) {

            $('.form-builder-recaptcha').each(function(){
                let formId = $(this).data('form');
                let recaptchaId = $(this).attr('id');
                form_expireCaptcha(formId);
                grecaptcha.render(recaptchaId, {
                    'callback': function() {
                        form_correctCaptcha(formId)
                    },
                    'expired-callback': function() {
                        form_expireCaptcha(formId)
                    },
                });
            })
        }
        else {
            setTimeout(() => {
                recaptchaFormsCallback()
            }, 500)
        }
    }
}

function form_correctCaptcha(formId) {

    $('#' + formId + ' .form-builder-submit').removeClass('disabled').prop('disabled', false);
}

function form_expireCaptcha(formId) {

    $('#' + formId + ' .form-builder-submit').addClass('disabled').prop('disabled', true);
}
